import React, { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import { navigate } from "gatsby";
// import { useFlexSearch } from "react-use-flexsearch";
import {
  useApp,
  useBio,
  useScroll,
  useSearch,
  useWindowDimensions,
  useKeyPress
} from "~hooks";
import { EASING_CUBIC, Keyframes } from "~styles/Animations.jsx";
import * as A from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import { Button, Go } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import { ReactComponent as LogoBlack } from "~assets/svg/logo-black.svg";
import { ReactComponent as LogoWhite } from "~assets/svg/logo-white.svg";
import { ReactComponent as Search } from "~assets/svg/search.svg";

const Header = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { locatorActive, menuActive, setMenuActive, pathname } = useApp();
  const bio = useBio();
  const { searchTerm, setSearchTerm } = useSearch();

  const [headerVisible, setHeaderVisible] = useState(false);
  const [hovering, setHovering] = useState(null);
  const [foregroundColor, setForegroundColor] = useState();
  const [scrolled, setScrolled] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  const { scrollingDown, scrollTop } = useScroll();
  const { screen, windowSize } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // variables

  const links = [
    {
      label: `About us`,
      target: `/about-us`
    },
    {
      label: `Our team`,
      target: `/our-team`
    },
    {
      label: `FRC research`,
      target: `/frc-research`
    },
    {
      label: `Our services`,
      target: `/our-services`
    },
    {
      label: `Costs`,
      target: `/costs`
    }
  ];

  // ---------------------------------------------------------------------------
  // methods

  const determineForegroundColor = () => {
    if (
      pathname?.includes(`/about-us`) ||
      pathname?.includes(`/costs`) ||
      pathname?.includes(`/faq`) ||
      pathname?.includes(`/news-and-events`) ||
      pathname?.includes(`/our-services`) ||
      pathname?.includes(`/our-team`) ||
      pathname?.includes(`/patient-form`) ||
      pathname?.includes(`/resource-form`) ||
      pathname?.includes(`/search`) ||
      pathname?.includes(`/events`) ||
      pathname?.includes(`/news`) ||
      pathname?.includes(`/gp-referral`) ||
      pathname?.includes(`/dr-referral`) ||
      pathname?.includes(`/frc-research`) ||
      pathname?.includes(`/understanding-your-fertility`)
    ) {
      setForegroundColor(getColor(`black`));
    } else {
      setForegroundColor(getColor(`white`));
    }
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  const escPressed = useKeyPress(`Escape`);

  useEffect(() => {
    setSearchActive(false);
  }, [escPressed]);

  useEffect(() => {
    if (!windowSize?.width) {
      return;
    }
    if (screen === `xs` || screen === `sm`) setScrolled(scrollTop > 300);
    else setScrolled(scrollTop > windowSize.width * 0.5125);
  }, [scrollTop]);

  useEffect(() => {
    setHeaderVisible(scrollingDown && scrollTop > 300);
  }, [scrollTop, scrollingDown]);

  useEffect(() => {
    determineForegroundColor();
  }, [pathname]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    menuActive
      ? setForegroundColor(getColor(`white`))
      : determineForegroundColor();
  }, [menuActive]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <header
      css={[
        css`
          transition: background 0.3s ${EASING_CUBIC},
            color 0.3s ${EASING_CUBIC}, opacity 0.3s ${EASING_CUBIC},
            transform 0.5s ${EASING_CUBIC};

          color: ${scrolled ? getColor(`white`) : foregroundColor};
          opacity: ${headerVisible || bio?.visible ? 0 : 1};
          pointer-events: ${headerVisible || bio?.visible ? `none` : `auto`};

          //
          background: ${scrolled && !menuActive
            ? getColor(`blue-med`)
            : `transparent`};

          ${MEDIA_QUERIES.desktop} {
            transform: translate3d(0, ${locatorActive ? `3rem` : `0`}, 0);

            background: ${scrolled ? getColor(`blue-med`) : `transparent`};
          }
        `,
        tw`w-full fixed top-0 right-0 left-0 z-40 flex justify-between px-8 pb-6`
      ]}
    >
      <div
        css={[
          css`
            ${Keyframes(`appearDown`, {
              delay: `0.15s`,
              settings: `0.75s ${EASING_CUBIC} forwards`
            })}
          `,
          tw`relative pt-4`
        ]}
      >
        <Go to="/">
          {scrolled ||
          foregroundColor === `white` ||
          foregroundColor?.toLowerCase() === `#ffffff` ? (
            <LogoWhite css={[tw`w-20 md:w-24 lg:w-28`]} />
          ) : (
            <LogoBlack css={[tw`w-20 md:w-24 lg:w-28`]} />
          )}
        </Go>
      </div>

      {/* // mobile burger // */}
      <div css={[tw`w-12 h-12 relative flex justify-end md:hidden mt-2 -mr-3`]}>
        <button
          type="button"
          css={[tw`w-full h-full relative block`]}
          onClick={() => {
            setMenuActive(!menuActive);
          }}
        >
          <div
            css={[
              tw`w-full h-full relative flex flex-col items-center justify-center`
            ]}
          >
            <div
              css={[
                css`
                  transition: transform 0.3s ${EASING_CUBIC};
                  transform: translate3d(0, ${menuActive ? `5px` : 0}, 0)
                    rotate(${menuActive ? `-45deg` : `0`});

                  height: 2px;
                  background: ${foregroundColor};
                `,
                tw`w-4 relative block`
              ]}
            />
            <div
              css={[
                css`
                  transition: transform 0.3s ${EASING_CUBIC};
                  transform: scaleX(${menuActive ? `0` : `1`});

                  height: 2px;
                  margin: 3px 0;
                  background: ${foregroundColor};
                `,
                tw`w-4 relative block`
              ]}
            />
            <div
              css={[
                css`
                  transition: transform 0.3s ${EASING_CUBIC};
                  transform: translate3d(0, ${menuActive ? `-5px` : 0}, 0)
                    rotate(${menuActive ? `45deg` : `0`});

                  height: 2px;
                  background: ${foregroundColor};
                `,
                tw`w-4 relative block`
              ]}
            />
          </div>
        </button>
      </div>

      <div
        css={[
          css`
            ${Keyframes(`appearDown`, {
              delay: `0.3s`,
              settings: `0.75s ${EASING_CUBIC} forwards`
            })}

            @media screen and (min-width: 1025px) {
              .link-container {
                width: 440px;
              }

              .link-text {
                font-size: 14px !important;
              }
            }

            @media screen and (min-width: 1140px) {
              .link-container {
                width: 560px;
              }

              .link-text {
                font-size: 18px !important;
              }
            }

            @media screen and (min-width: 1400px) {
              .link-container {
                width: 664px;
              }
            }
          `,
          tw`h-16 relative hidden md:flex items-center pt-8`
        ]}
      >
        <ul
          className="link-container"
          css={[
            css`
              //
            `,
            tw`relative flex items-center justify-between md:mr-6 lg:mr-12`
          ]}
        >
          {links.map(({ label, target }) => {
            let opacity = 1;

            if (hovering !== null) {
              opacity = hovering === label ? 1 : 0.5;
            }

            return (
              <li key={`header-link-${label}`} css={[tw`relative`]}>
                <Go to={target}>
                  <div
                    role="presentation"
                    onMouseEnter={() => setHovering(label)}
                    onMouseLeave={() => setHovering(null)}
                  >
                    <T.Body
                      className="link-text"
                      _css={[
                        css`
                          transition: 0.3s ease opacity;

                          opacity: ${opacity};
                        `,
                        tw`font-bold`
                      ]}
                      font="2"
                    >
                      {label}
                    </T.Body>
                  </div>
                </Go>
              </li>
            );
          })}
        </ul>

        <div css={[tw`relative hidden md:flex items-stretch`]}>
          <div
            css={[
              css`
                transition: 0.3s ${A.EASING_CUBIC} opacity,
                  0.3s ${A.EASING_CUBIC} transform;

                opacity: ${searchActive ? 1 : 0};
                pointer-events: ${searchActive ? `auto` : `none`};
                transform: translate3d(0, ${searchActive ? `0` : `-1rem`}, 0);

                bottom: -3rem;
              `,
              tw`w-full h-10 absolute right-0 flex items-center justify-between`
            ]}
          >
            <input
              css={[
                css`
                  position: relative;
                  display: block;

                  ${MEDIA_QUERIES.desktop} {
                    width: calc(100% - 4rem);
                  }

                  ${MEDIA_QUERIES.large} {
                    width: calc(100% - 5rem);
                  }
                `,
                tw`h-10 relative px-2 bg-white text-black text-b1 md:text-b1-md`
              ]}
              onChange={(e) => {
                setSearchTerm(e.currentTarget.value);
              }}
              onKeyUp={({ key }) => {
                if (key === `Enter`) {
                  navigate(`/search`);
                }
              }}
              placeholder="Search"
              type="text"
              value={searchTerm}
            />

            <Go
              to="/search"
              _css={[
                tw`w-10 h-10 relative block rounded-full overflow-hidden bg-white`
              ]}
              onClick={() => {
                setSearchActive(false);
              }}
            >
              <div
                css={[
                  tw`w-full h-full relative flex items-center justify-center`
                ]}
              >
                <ArrowRight css={[tw`w-4`]} />
              </div>
            </Go>
          </div>

          <Go
            to="/get-started"
            _css={[
              css`
                flex: 0 1 auto;
              `,
              tw`block`
            ]}
          >
            <Button
              className="link-text"
              _css={[tw`md:w-36 lg:w-48 h-full relative mr-4`]}
              small
              text="Get started"
            />
          </Go>

          <Go
            to="/dr-referral"
            _css={[
              css`
                flex: 0 1 auto;
              `,
              tw`block`
            ]}
          >
            <Button
              className="link-text"
              _css={[tw`md:w-36 lg:w-48 h-full relative`]}
              small
              text="Dr referral"
            />
          </Go>

          <div
            css={[
              tw`w-10 h-10 relative block rounded-full overflow-hidden md:ml-6 lg:ml-10`
            ]}
          >
            <button
              type="button"
              onClick={() => {
                setSearchActive(!searchActive);
              }}
              css={[tw`w-10 h-10 relative block bg-white`]}
            >
              <div
                css={[
                  tw`w-full h-full relative flex items-center justify-center`
                ]}
              >
                {searchActive ? (
                  <Cross
                    css={[
                      css`
                        transform: rotate(45deg);
                      `,
                      tw`w-4`
                    ]}
                  />
                ) : (
                  <Search css={[tw`w-4`]} />
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
